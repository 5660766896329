<script setup lang="ts">
const accountStore = useAccountStore();
const baseStore = useBaseStore();
const chatStore = useChatStore();
const router = useRouter();

const { t } = useI18n({
  useScope: 'local',
});

const countOfUnseenConversations = computed(() => baseStore.state.countOfUnseenConversations);
const initialized = computed(() => accountStore.initializedAt);
const activeChatRoom = computed(() => chatStore.activeChatRoom);

watchEffect(async () => {
  if (!initialized.value) {
    await accountStore.executeStatisticsRequest(true);
  }
});

const activeLink = computed(() => {
  const links = {
    watchlist: '/dashboard/matches?status=watchlist',
    matches: '/dashboard/matches?status=all',
    messages: '/messaging/dashboard',
    more: '/advanced/navigation',
  };

  for (const link in links) {
    if (router.currentRoute.value.fullPath.includes(links[link as keyof typeof links])) {
      return link;
    }
  }
});
</script>

<template>
  <nav v-if="!activeChatRoom" class="mobile-navigation">
    <ul>
      <li>
        <NuxtLink to="/dashboard/matches?status=watchlist" :class="['link', 'watchlist', { active: activeLink === 'watchlist' }]">
          <Icon name="bi:heart-fill" class="icon icon-active" />
          <Icon name="bi:heart" class="icon icon-inactive" />
          {{ t('navigation.watchlist') }}
        </NuxtLink>
      </li>

      <li>
        <NuxtLink to="/dashboard/matches?status=all" :class="['link', 'matches-all', { active: activeLink === 'matches' }]">
          <Icon name="bi:house-door-fill" class="icon icon-active" />
          <Icon name="bi:house-door" class="icon icon-inactive" />
          <span>{{ t('navigation.matches') }}</span>
        </NuxtLink>
      </li>

      <li>
        <template v-if="countOfUnseenConversations > 0">
          <TwCircledNumber :number="countOfUnseenConversations" class="unseen-conversations" />
        </template>
        <NuxtLink to="/messaging/dashboard" :class="['link', 'messages', { active: activeLink === 'messages' }]">
          <Icon name="bi:chat-fill" class="icon icon-active" />
          <Icon name="bi:chat" class="icon icon-inactive" />
          <span>{{ t('navigation.messages') }}</span>
        </NuxtLink>
      </li>

      <li>
        <NuxtLink to="/advanced/navigation" :class="['link', 'more', { active: activeLink === 'more' }]">
          <Icon name="bi:three-dots" class="icon icon-active" />
          <Icon name="bi:three-dots" class="icon icon-inactive" />
          <span>{{ t('navigation.more') }}</span>
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/tw-variables';

.mobile-navigation {
  ul {
    display: flex;
    list-style: none;
    position: relative;
    align-items: center;
    flex-direction: row;
    background-color: $white;
    justify-content: space-around;
    border-top: 1px solid $lightGray;
    height: calc(4rem + env(safe-area-inset-bottom, 2rem));
    padding: 0.5rem 0 calc(0.5rem + env(safe-area-inset-bottom, 2rem)) 0;

    li {
      position: relative;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9rem;
        text-decoration: none;
      }
      .link {
        &.subscription {
          background: $success;
          padding: 0.25rem;
          color: $white;
          border-radius: 4px;
        }
      }
    }
  }
}

.unseen-conversations {
  top: -5px;
  z-index: 1;
  right: 1rem;
  position: absolute;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.link {
  .icon-inactive {
    display: block;
  }
  .icon-active {
    display: none;
  }
}

.link.active {
  color: $black;

  .icon-inactive {
    display: none;
  }
  .icon-active {
    display: block;
  }

  span {
    font-weight: bold;
  }
}
</style>

<i18n lang="json">
{
  "de": {
    "navigation": {
      "watchlist": "Merkliste",
      "matches": "Matches",
      "subscription": "Abo",
      "messages": "Nachrichten",
      "more": "Mehr",
      "search": "Suche",
      "register": "Registrieren",
      "login": "Anmelden"
    }
  },
  "en": {
    "navigation": {
      "watchlist": "Watchlist",
      "matches": "Matches",
      "subscription": "Subscription",
      "messages": "Messages",
      "more": "More",
      "search": "Search",
      "register": "Register",
      "login": "Login"
    }
  }
}
</i18n>
